// eslint-disable-next-line max-len
/* eslint-disable max-len, react/jsx-curly-brace-presence, react/jsx-closing-tag-location, react/no-unused-prop-types, no-unused-vars, react/style-prop-object, react/jsx-max-props-per-line, react/jsx-indent-props, react/jsx-first-prop-new-line, react/no-unused-state, react/jsx-indent, react/jsx-closing-bracket-location, react/self-closing-comp, react/self-closing-comp */

import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import Calculator from '../../components/Calculator';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import {
  CommonPageSection,
  Content,
  ContentItemWrap,
  ContentItem,
  Sidebar,
  LinksBlock,
} from '../../components/CommonPageSection';
import { PolicyPageText } from '../../components/SitePolicies';
import CTA from '../../components/CTA';

import './index.scss';


const TermsConditionsPage = () => (
  <Layout
    pageWrapperClassName="term-conditions-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Terms of Use | TakeAwayEssay.com"
      meta={[
        { name: 'description', content: '' },

        { name: 'robots', content: 'noindex,nofollow' },

        { property: 'og:title', content: 'Terms of Use | TakeAwayEssay.com' },
        { property: 'og:description', content: '' },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/terms-conditions.html' },
      ]}
    />

    <CommonPageSection>
      <Content>
        <ContentItemWrap>
          <VerticalTextBlock
            sectionClassName="common-page"
            dotsStyle="style-4"
            text="Terms & Conditions"
          />

          <ContentItem className="term-conditions-content">

            <h1>
              Terms of Use
            </h1>
            <PolicyPageText policyTypeId="2" />

          </ContentItem>
        </ContentItemWrap>
      </Content>
      <Sidebar>
        <LinksBlock />
      </Sidebar>
    </CommonPageSection>

    <Calculator />

    <CTA isOnlyWaves />

  </Layout>
);

export default TermsConditionsPage;
